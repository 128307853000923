<template>
    <div>
        <div class="flex flex-row justify-content-between align-items-center">
            <div>
                <b-tabs pills v-model="tab" class="border-2">
                    <b-tab title="مرحلة التعليم الجامعي" active> </b-tab>
                    <b-tab title="مرحلة التعليم المدرسي"> </b-tab>
                </b-tabs>
            </div>
            <div class="w-[50%]">
                <div class="flex" style="margin-top: -18px">
                    <ek-dialog
                        v-if="tab == 0"
                        ref="newdialog"
                        title="استيراد بنوك التعليم الجامعي"
                        size="md"
                        btnText="استيراد بنوك التعليم الجامعي "
                        @ok="ok()"
                    >
                        <template #body>
                            <validationObserver ref="newdialogForm">
                                <ek-input-image
                                    class="mt-1"
                                    ref="imgFile"
                                    label=" الملف  "
                                    name="الملف "
                                    :accept="'.xlsx, .xls, .csv'"

                                    @input="storeExcelFile($event)"
                                >
                                    <h5>انقر لتحميل الملف</h5>
                                </ek-input-image>
                                <ek-input-select
                                    v-model="SubjectId"
                                    label="المادة"
                                    placeholder="اختر المادة "
                                    :options="subjectsNames"
                                    name="select"
                                    :clearable="true"
                                />
                            </validationObserver>
                        </template>
                    </ek-dialog>
                    <ek-dialog
                        v-if="tab == 1"
                        ref="newExceldialog"
                        title="استيراد بنوك التعليم الاساسي"
                        size="md"
                        btnText="استيراد بنوك التعليم الاساسي "
                        @ok="AddExcel()"
                    >
                        <template #body>
                            <validationObserver ref="newdialogFormForExcel">
                                <ek-input-image
                                    class="mt-1"
                                    ref="imgFile"
                                    label=" الملف  "
                                    name="الملف "
                                    :accept="'.xlsx, .xls, .csv'"
                                    @input="storeExcelFile($event)"
                                >
                                    <h5>انقر لتحميل الملف</h5>
                                </ek-input-image>
                                <ek-input-select
                                    v-model="SubjectId"
                                    label="المادة"
                                    placeholder="اختر المادة "
                                    :options="subjectsNames"
                                    name="select"
                                    :clearable="true"
                                />
                            </validationObserver>
                        </template>
                    </ek-dialog>
                </div>
            </div>
        </div>
        <ek-table
            :columns="columns"
            :items="previousExamsList.previousExams"
            @delete-selected="deleteExam"
        >
            <template slot="items.isFree" slot-scope="{ value }">
                <span
                    class="rounded text-white"
                    :class="value == true ? 'bg-primary' : 'bg-danger'"
                    style="padding: 5px"
                >
                    {{ value ? "مجاني" : "مدفوع" }}</span
                >
            </template>
            <template slot="items.id" slot-scope="{ value }">
                <b-button
                    size="sm"
                    variant="flat-secondary"
                    class="btn-icon rounded-circle"
                    style="padding: 2px 6px !important"
                    @click="previousExamsDetails(value)"
                >
                    <unicon name="ellipsis-v" width="18"></unicon>
                </b-button>
            </template>
        </ek-table>
    </div>
</template>

<script>
// import axiosIns from "@/libs/axios";
import { mapActions, mapGetters, mapState } from "vuex";
// import "vue-good-table/dist/vue-good-table.css";
export default {
    data() {
        return {
            tab: 0,

            lessonsTableCols: [
                {
                    label: "الاسم ",
                    field: "order",
                },
                {
                    label: "الحالة ",
                    field: "order",
                },
            ],
            subjectId: "",
            filterDto: {
                PreviousExamType: "QuestionBank",
                subjectId: null,
                StudentType: this.tab,
            },
        };
    },
    computed: {
        ...mapState({
            previousExamsList: (state) => state.previousExams.previousExamsList,
            columns: (state) => state.previousExams.columns,
            hello: (state) => state.previousExams.hello,
            subjectsNames: ({ subjects }) => subjects.subjectsNames,

        }),
        ...mapGetters(["allBros"]),
    },
    methods: {
        ...mapActions([
            "getPreviousExams",
            "getSubjectsNames",
            "AddFromExcel",
            "AddFromSchoolExcel",
            "deletePreviousExams",
        ]),
        storeExcelFile(event) {
            console.log(event);
            if (event && event.file) {
                this.file = event.file;
            }
        },
        previousExamsDetails(id) {
            console.log("fdf", id);
            this.$router.push(`/admin/CourseQuestions/${id}`);
        },
        deleteExam(e) {
            console.log(e);
            this.$store.dispatch("deletePreviousExams", e).finally(() => {
                this.getPreviousExams({
            ...this.filterDto,
            PreviousExamType: "QuestionBank",
            StudentType: 0,
        });
            });
        

         

        },
        ok() {
            this.$refs.newdialogForm.validate().then((success) => {
                if (success) {
                    console.log(this.file, this.SubjectId);
                    this.AddFromExcel({
                        PreviousExamFile: this.file,
                        SubjectId: this.SubjectId,
                        previousExamType: "QuestionBank"

                    });
                }
            });
        },
        AddExcel() {
            this.$refs.newdialogFormForExcel.validate().then((success) => {
                if (success) {
                    console.log(this.file, this.SubjectId);
                    this.AddFromSchoolExcel({
                        PreviousExamFile: this.file,
                        
                        SubjectId: this.SubjectId,
                        previousExamType: "QuestionBank"

                    });
                }
            });
        },
    },
    created() {
        this.getPreviousExams({
            ...this.filterDto,
            PreviousExamType: "QuestionBank",
            StudentType: 0,
        });
        this.getSubjectsNames(0);
    },
    watch: {
        tab: function (newTab) {
            if (newTab == 0) {
                console.log(newTab, "dddddddddd");

                this.getPreviousExams({
                    ...this.filterDto,
                    PreviousExamType: "QuestionBank",
                    StudentType: 0,
                });
                this.getSubjectsNames(0);
            } else {
                this.getPreviousExams({
                    ...this.filterDto,
                    PreviousExamType: "QuestionBank",
                    StudentType: 1,
                });
                this.getSubjectsNames(1);
            }
        },
    },
};
</script>
